import React, { useState, useEffect, Fragment } from "react";
import styles from "../skiSchool.module.scss";
import { Grid, Select } from "@material-ui/core";
import ski from "../../../img/ski.svg";
import age from "../../../img/age.svg";
import stairs from "../../../img/stairs.svg";
import arrow from "../../../img/blueArrow.png";
import { withRouter } from "react-router-dom";
// import dataArr from "./data.json";
import usePrivateData from "./lessonsData/privateLessonsData";
import useGroupData from "./lessonsData/groupLessonsData";
import selectionArr from "./selection.json";
import useLocale from "../../../lang/useLocale";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import CustomSelect from "../../select/select";
// import DatePicker from './DatePicker'
// import { useDispatch } from "react-redux";
// import {
//   listOptionsActions,
//   filteredDataActions,
// } from "../../../redux/actions/skiSchoolActions";

const Search = (props) => {
  //   console.log(props.data);
  const lang = useSelector((state) => state.lang);
  // const activityList = useSelector((state) => state.activityListReducer);
  // const { data } = activityList;
  // const listOptions = useSelector((state) => state.listOptionsReducer);
  // const filteredData = useSelector((state) => state.filteredDataReducer);
  // console.log(filteredData.data);
  const { getLocale } = useLocale();
  const privateData = usePrivateData(lang);
  const groupData = useGroupData(lang);
  // const dispatch = useDispatch();
  const [lessonsData, setLessonsData] = useState([
    ...privateData,
    ...groupData,
  ]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [lessonArr, setLessonArr] = useState([]);
  const [activity, setActivity] = useState("Skiing");

  function handleSearch(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    let activity = data.get("Activity");
    let age = data.get("Age");
    let level = data.get("Level");
    let privacy = data.get("Privacy");
    // console.log(activity, age, level, privacy);
    if (!activity) {
      setMessage(getLocale("choosePlease1"));
      setOpen(true);
    } else if (!age) {
      setMessage(getLocale("choosePlease2"));
      setOpen(true);
    } else if (!level) {
      setMessage(getLocale("choosePlease3"));
      setOpen(true);
    } else if (!privacy) {
      setMessage(getLocale("choosePlease4"));
      setOpen(true);
    } else {
      setOpen(false);
      setMessage("");
    }
    if (activity && age && level && privacy) {
      let filtered = lessonsData.filter((obj) => {
        return (
          obj.activity === activity &&
          obj.age === age &&
          obj.level === level &&
          obj.privacy === privacy
        );
      });
      setLessonArr(filtered);
      // dispatch(filteredDataActions(activity, age, level, privacy));
    }
  }

  function handleActivity(e) {
    setActivity(e.target.value);
    // dispatch(listOptionsActions(e.target.value));
  }
  let filtered = selectionArr.find(function (filtered) {
    return Object.keys(filtered)[0] === activity;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setLessonsData([...privateData, ...groupData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {
    if (lessonArr.length > 0) {
      let newLessonArr = [];
      lessonArr.forEach((item) => {
        newLessonArr.push(
          lessonsData.find(
            (lesson) =>
              lesson.activity === item.activity &&
              lesson.age === item.age &&
              lesson.level === item.level &&
              lesson.privacy === item.privacy
          )
        );
      });
      setLessonArr(newLessonArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonsData]);
  // useEffect(() => {}, [listOptions, filteredData]);
  return (
    <Fragment>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={message}
          action={
            <Fragment>
              {/* <Button color="secondary" size="small" onClick={handleClose}>
                            اغلاق
                        </Button> */}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Fragment>
          }
        />
      </div>
      <form onSubmit={(e) => handleSearch(e)}>
        <div className={styles.search}>
          <Grid container justify="center">
            <Grid item md={11} sm={12} className={styles.bar}>
              <Grid
                container
                direction={lang === "en" ? "row" : "row-reverse"}
                justify="space-between"
                alignItems="center"
              >
                <Grid container item md={9} xs={12}>
                  <Grid item md={3} xs={12} className={styles.searchField}>
                    <img src={ski} alt="img" />
                    <Select
                      native
                      name="Activity"
                      displayEmpty
                      className={styles.searchInput}
                      IconComponent={() => (
                        <img src={arrow} className={styles.arrow} alt="img" />
                      )}
                      defaultValue=""
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      onChange={handleActivity}
                    >
                      <option
                        disabled
                        value=""
                        style={{
                          color: "#afafaf",
                        }}
                      >
                        {getLocale("Activity")}
                      </option>
                      {selectionArr.map((select, index) => {
                        return (
                          <option
                            key={index}
                            value={Object.keys(select)[0]}
                            style={{
                              color: "#afafaf",
                            }}
                          >
                            {lang === "en"
                              ? Object.keys(select)[0]
                              : Object.keys(select)[0] === "Skiing"
                              ? "سكي"
                              : "سنوبورد"}
                            {/* {select.activity} */}
                          </option>
                        );
                      })}
                      {/* // ) : (
                      //   <option
                      //     style={{
                      //       color: "#afafaf",
                      //     }}
                      //   >
                      //     loading..
                      //   </option>
                      // )} */}
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12} className={styles.searchField}>
                    <img src={age} alt="img" />
                    <Select
                      native
                      name="Age"
                      displayEmpty
                      className={styles.searchInput}
                      IconComponent={() => (
                        <img src={arrow} className={styles.arrow} alt="img" />
                      )}
                      defaultValue=""
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <option
                        disabled
                        value=""
                        style={{
                          color: "#afafaf",
                        }}
                      >
                        {getLocale("Age")}
                      </option>
                      {activity === "Skiing"
                        ? filtered.Skiing.age.map((age, index) => {
                            // if (age.key === "age")
                            return (
                              <option
                                key={index}
                                value={age}
                                style={{
                                  color: "#afafaf",
                                }}
                              >
                                {" "}
                                {lang === "en"
                                  ? age
                                  : age === "3-6 Yrs"
                                  ? "3-6 سنة"
                                  : age === "7-13 Yrs"
                                  ? "7-13 سنة"
                                  : age === "Adults"
                                  ? "كبار"
                                  : null}
                              </option>
                            );
                          })
                        : filtered.Snowboarding.age.map((age, index) => {
                            return (
                              <option
                                key={index}
                                value={age}
                                style={{
                                  color: "#afafaf",
                                }}
                              >
                                {" "}
                                {lang === "en"
                                  ? age
                                  : age === "7-13 Yrs"
                                  ? "7-13 سنة"
                                  : age === "8-13 Yrs"
                                  ? "8-13 سنة"
                                  : age === "Adults"
                                  ? "كبار"
                                  : null}
                              </option>
                            );
                          })}
                      {/* </option>
                            );
                          })} */}
                      {/* // ) : (
                      //   <option
                      //     style={{
                      //       color: "#afafaf",
                      //     }}
                      //   >
                      //     loading..
                      //   </option>
                      // )} */}
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12} className={styles.searchField}>
                    <img src={stairs} alt="img" />
                    <Select
                      native
                      name="Level"
                      displayEmpty
                      className={styles.searchInput}
                      IconComponent={() => (
                        <img src={arrow} className={styles.arrow} alt="img" />
                      )}
                      defaultValue=""
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <option
                        disabled
                        value=""
                        style={{
                          color: "#afafaf",
                        }}
                      >
                        {getLocale("Level")}
                      </option>
                      {activity === "Skiing"
                        ? filtered.Skiing.level.map((level, index) => {
                            // if (level.key === "level")
                            return (
                              <option
                                key={index}
                                value={level}
                                style={{
                                  color: "#afafaf",
                                }}
                              >
                                {" "}
                                {lang === "en"
                                  ? level
                                  : level === "Beginner"
                                  ? "مبتدئ"
                                  : level === "Discovery"
                                  ? "استكشافي"
                                  : level === "Level 1"
                                  ? "المستوي 1"
                                  : level === "Level 2"
                                  ? "المستوي 2"
                                  : level === "Level 3"
                                  ? "المستوي 3"
                                  : level === "Level 4"
                                  ? "المستوي 4"
                                  : null}
                                {/* {level.value} */}
                              </option>
                            );
                          })
                        : filtered.Snowboarding.level.map((level, index) => {
                            return (
                              <option
                                key={index}
                                value={level}
                                style={{
                                  color: "#afafaf",
                                }}
                              >
                                {" "}
                                {lang === "en"
                                  ? level
                                  : level === "Beginner"
                                  ? "مبتدئ"
                                  : level === "Discovery"
                                  ? "استكشافي"
                                  : level === "Level 1"
                                  ? "المستوي 1"
                                  : level === "Level 2"
                                  ? "المستوي 2"
                                  : level === "Level 3"
                                  ? "المستوي 3"
                                  : level === "Level 4"
                                  ? "المستوي 4"
                                  : null}
                              </option>
                            );
                          })}
                    </Select>
                  </Grid>
                  <Grid item md={3} xs={12} className={styles.searchField}>
                    <img src={age} alt="img" />
                    <Select
                      native
                      name="Privacy"
                      displayEmpty
                      className={styles.searchInput}
                      IconComponent={() => (
                        <img src={arrow} className={styles.arrow} alt="img" />
                      )}
                      defaultValue=""
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <option
                        disabled
                        value=""
                        style={{
                          color: "#afafaf",
                        }}
                      >
                        {" "}
                        {getLocale("Private / Group")}
                      </option>
                      {activity === "Skiing"
                        ? filtered.Skiing.privacy.map((privacy, index) => {
                            // if (privacy.key === "group")
                            return (
                              <option
                                key={index}
                                value={privacy}
                                style={{
                                  color: "#afafaf",
                                }}
                              >
                                {" "}
                                {lang === "en"
                                  ? privacy
                                  : privacy === "Private"
                                  ? "فردي"
                                  : privacy === "Group"
                                  ? "جماعي"
                                  : null}
                              </option>
                            );
                          })
                        : filtered.Snowboarding.privacy.map(
                            (privacy, index) => {
                              return (
                                <option
                                  key={index}
                                  value={privacy}
                                  style={{
                                    color: "#afafaf",
                                  }}
                                >
                                  {" "}
                                  {lang === "en"
                                    ? privacy
                                    : privacy === "Private"
                                    ? "فردي"
                                    : privacy === "Group"
                                    ? "جماعي"
                                    : null}
                                </option>
                              );
                            }
                          )}
                    </Select>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12}>
                  <button type="sumbit" className={styles.SearchBtn}>
                    {getLocale("FIND PACKAGES")}
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={1}
            className={styles.table}
          >
            {lessonArr &&
              lessonArr.map((lesson, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    {/* <Grid item xs={12}> */}
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Fragment>
                        {lesson.lessons.map((singelLesson, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              md={4}
                              style={{ textAlign: "center" }}
                            >
                              <div className={styles.lesson}>
                                {/* <h4>{lesson.name}</h4> */}
                                {/* <p>{lesson.description}</p> */}
                                <h4>
                                  {lang === "en"
                                    ? `${lesson.privacy} ${lesson.activity} Lesson`
                                    : "الدرس"}
                                </h4>
                                <p>{`${
                                  singelLesson.time
                                    ? `${singelLesson.time} minutes`
                                    : ""
                                } ${lesson.privacy} ${lesson.activity} ${
                                  lesson.level
                                } lesson for ${lesson.age}`}</p>
                                <a
                                  href={singelLesson.link}
                                  target="_self"
                                  rel="noopener noreferrer"
                                  className={styles.book}
                                >
                                  {getLocale("BOOK NOW")}
                                </a>
                              </div>
                            </Grid>
                          );
                          // {/* <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                          // 	<div className={styles.lesson}>
                          // 		<h4>{lang === "en" ? `${lesson.privacy} ${lesson.activity} Lesson` : "الدرس"}</h4>
                          // 		<p>{`${lesson.duration1} minutes ${lesson.privacy} ${lesson.activity} ${lesson.level} lesson for ${lesson.age}`}</p>
                          // 		<a href={lesson.link} target="_blank" rel="noopener noreferrer" className={styles.book}>
                          // 			{getLocale("BOOK NOW")}
                          // 		</a>
                          // 	</div>
                          // </Grid> */}
                          // {/* <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                          // 	<div className={styles.lesson}>
                          // 		<h4>{lang === "en" ? `${lesson.privacy} ${lesson.activity} Lesson` : "الدرس"}</h4>
                          // 		<p>{`${lesson.duration2} minutes ${lesson.privacy} ${lesson.activity} ${lesson.level} lesson for ${lesson.age}`}</p>
                          // 		<a href={lesson.link} target="_blank" rel="noopener noreferrer" className={styles.book}>
                          // 			{getLocale("BOOK NOW")}
                          // 		</a>
                          // 	</div>
                          // </Grid> */}
                          // {/* <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                          // 	<div className={styles.lesson}>
                          // 		<h4>{lang === "en" ? `${lesson.privacy} ${lesson.activity} Lesson` : "الدرس"}</h4>
                          // 		<p>{`${lesson.duration3} minutes ${lesson.privacy} ${lesson.activity} ${lesson.level} lesson for ${lesson.age}`}</p>
                          // 		<a href={lesson.link} target="_blank" rel="noopener noreferrer" className={styles.book}>
                          // 			{getLocale("BOOK NOW")}
                          // 		</a>
                          // 	</div>
                          // </Grid> */}
                        })}
                      </Fragment>
                    </Grid>
                    {/* </Grid> */}
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </form>
    </Fragment>
  );
};

export default withRouter(Search);
